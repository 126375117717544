import React from 'react';
import PropTypes from 'prop-types';
import styles from './NotEligible.module.scss';

const NotEligible = () => (
  <div className={styles.NotEligible} data-testid="NotEligible">
    <div className="info-box">
      <div className="info-box-icon">
        <svg id="i-info" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
          <path d="M16 14 L16 23 M16 8 L16 10" />
          <circle cx="16" cy="16" r="14" />
        </svg>
      </div>
      <div className="info-box-content">
        
      Sorry, only users who swapped for participation certificates are
      eligible to use this application. Please contact our
      <a className="link" href="https://modum-token-swap.atlassian.net/servicedesk/customer/portal/1" target="_blank">support</a>
      if you should have access.
      </div>
    </div>
  </div>
);

NotEligible.propTypes = {};

NotEligible.defaultProps = {};

export default NotEligible;
