import React from 'react';
import PropTypes from 'prop-types';
import styles from './BankAccountNo.module.scss';

import InputField from './../InputField'
import { Validators } from './../../utilities/Validator'
import axios from 'axios'

let ibanCancelationToken;

const IbanServiceValidation = {
  DEFAULT: 'default',
  INPROGRESS: 'inprogress',
  SUCCESS: 'success',
  FAIL: 'fail',
}
class BankAccountNo extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      editMode: false,
      ibanValidation: IbanServiceValidation.DEFAULT,
      ibanOrAccountNumberInputValue: "",
      model: {
        iban: "",
        name: "",
        bic: "",
        accountNumber: ""
      }
    }

    this.inputs = {
      ibanOrAccountNumberInputValue: React.createRef(),
      name: React.createRef(),
      bic: React.createRef()
    }

    this.ibanValidationTimeoutHandle = null;
    this.ibanValidationUpdateTimeoutMs = 800;
  }

  setEditMode = (yes) => {

    this.setState({ 
      editMode: yes,
      ibanValidation: IbanServiceValidation.DEFAULT
    });

    if (yes) {
      setImmediate(() => {
        this.inputs.ibanOrAccountNumberInputValue.current.focus();
      });
    }
  }

  resetModel = () => {

    if(ibanCancelationToken) { 
      ibanCancelationToken.cancel();
    }            

    //support for editing multiple data fields in one input
    this.state.model.iban = '';
    this.state.model.accountNumber = '';

    Object.keys(this.untouchedCopy).forEach(key => {
      this.state.model[key] = this.untouchedCopy[key];
    })

    this.setState({
      ibanOrAccountNumberInputValue: this.state.model.iban ? this.state.model.iban : this.state.model.accountNumber,
      ibanValidation: IbanServiceValidation.DEFAULT
    })

    this.validate();
  }

  setModel = (model) => {
    this.untouchedCopy = JSON.parse(JSON.stringify(model));

    //support for editing multiple data fields in one input
    this.setState({
      ibanOrAccountNumberInputValue: model.iban ? model.iban : model.accountNumber,
      ibanValidation: IbanServiceValidation.DEFAULT
    })

    Object.keys(model).forEach(key => {
      this.setState({ model: { ...this.state.model, [key]: model[key] } })
    });
  }

  triggerSave = () => {
    
    if(ibanCancelationToken) { 
      ibanCancelationToken.cancel();
    }            

    if (!this.validate()) {
      this.props.onError("Form contains errors, please fill all mandatory fields.");
    } else {
      this.props.onSave(this.state.model);
    }
  }

  assignRelevantAccountField = (value, ibanValid, ibanName, ibanBic) => {

    if (ibanValid) {
      this.setState({
        ibanValidation: IbanServiceValidation.SUCCESS,
        model: {
          ...this.state.model,
          name: ibanName, 
          bic: ibanBic,
          iban: value,      //set
          accountNumber: '' //clear
        }
      });

      if(this.inputs.name.current)
         this.inputs.name.current.setState({ error: false });

      if(this.inputs.ibanOrAccountNumberInputValue.current)
         this.inputs.ibanOrAccountNumberInputValue.current.setState({ error: false });
    }
    else {
      this.setState({ 
        ibanValidation: IbanServiceValidation.FAIL, 
        model :{
          ...this.state.model,
          accountNumber: value, //set
          iban: ''              //clear
        }
      });
    }

  }
  
  handleChange = (key) => (value) => {

    /* support for editing of two data fields in one input field */
    if(key == 'ibanOrAccountNumberInputValue') {
        value = value.replace(/[^a-zA-Z0-9]/gi, "");
        this.setState({  [key]: value });

        //IBAN minimum length is 15
        if (!value || value.length < 15 || !value.trim().length) {
          this.setState({ ibanValidation: IbanServiceValidation.DEFAULT });
          this.assignRelevantAccountField(value, false);
        } 
        else 
        {
  
          
  
          //CH3209000000258159250
          if(this.ibanValidationTimeoutHandle) {
            clearTimeout(this.ibanValidationTimeoutHandle);
          }
          this.ibanValidationTimeoutHandle = setTimeout(() => {
            this.setState({ ibanValidation: IbanServiceValidation.INPROGRESS });
            if(ibanCancelationToken) { 
              ibanCancelationToken.cancel();
            }            
            ibanCancelationToken = axios.CancelToken.source()
            axios.get(`https://openiban.com/validate/${value}?getBIC=true&validateBankCode=true`,
              { 
                cancelToken: ibanCancelationToken.token 
              }).then(response => {
                console.log(response.data);

                
                //TODO: add cancel token to axios
                if(this.state.editMode) 
                {
                  //user might have canceled edit mode
                  var valid = response.data.valid &&
                    response.data.checkResults &&
                    response.data.checkResults.bankCode;
                  
                  this.assignRelevantAccountField(value, valid, response.data.bankData.name,  response.data.bankData.bic);
                  this.validate();
                }
              }).catch((err) => {
                  console.log('openiban-error', err);
                  this.assignRelevantAccountField(value, false);
              });
          }, this.ibanValidationUpdateTimeoutMs);
        }
    } 
    else 
    {
      this.setState({ model: { ...this.state.model, [key]: value } });
    }

  };

  validate = () => {

    var hasError = false;

    Object.keys(this.inputs).forEach(name => {
      if (this.inputs[name].current.validate() !== false) {
        hasError = true;
      }
    });

    this.setState({ hasError: hasError });

    return !hasError;

  }

  render() {
    const hasError = this.state.hasError;
    const editMode = this.state.editMode;

    return (
      <div className={styles.BankAccountNo} data-testid="BankAccountNo">

        <div className="profile-block">
          {this.props.header &&
            <div className="header">
              Bank account details
            </div>
          }
          <div className="content">

            <div className="data-row">
              <div className="data-label">
                <label>
                  <span className={ this.state.model.iban ? 'account-used' : '' }>
                    IBAN
                  </span>
                  /
                  <span className={ this.state.model.accountNumber ? 'account-used' : '' }>
                    Account Number
                  </span>
                </label>
              </div>
              <div className="data-prop">
                {!editMode && <div className="value">{this.state.model.iban || this.state.model.accountNumber || '[missing]'} </div>}
                {editMode &&
                  <div>
                    <InputField type='text'
                      ref={this.inputs.ibanOrAccountNumberInputValue}
                      value={this.state.ibanOrAccountNumberInputValue}
                      maxlength="100"
                      placeholder='IBAN/Account Number'
                      validators={[{ check: Validators.required, message: 'This field is required' }]}
                      onChange={this.handleChange('ibanOrAccountNumberInputValue')} />

                    <div>
                      {
                        this.state.ibanValidation == 'inprogress' &&
                        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                      }

                      {this.state.ibanValidation == 'success' &&
                        <p >
                          <strong className="iban-ok">Provided IBAN was recognized as correct. </strong>
                          <br /><strong>Bank name</strong> and <strong>BIC/SWIFT</strong> fields were filled automatically, please verify if they are correct.
                        </p>
                      }

                      {this.state.ibanValidation == 'fail' &&
                        <p >
                          <strong className="iban-warning">
                            
                            We could not verify your IBAN number automatically.
                          </strong><br/>
                          Please double-check provided IBAN/Bank Account number and if it is correct, fill in <strong> Bank name</strong> and <strong>BIC/SWIFT</strong>  manually below.
                        </p>
                      }
                    </div>
                  </div>
                }

              </div>
            </div>


            <div className="data-row">
              <div className="data-label">
                <label>Bank name</label>
              </div>
              <div className="data-prop">
                {!editMode && <div className="value">{this.state.model.name || '[missing]'} </div>}
                {editMode &&
                  <InputField type='text' maxlength={100}
                    ref={this.inputs.name}
                    value={this.state.model.name}
                    placeholder='Bank name'
                    validators={[{ check: Validators.required, message: 'This field is required' }]}
                    onChange={this.handleChange('name')} />
                }
              </div>
            </div>

            <div className="data-row">
              <div className="data-label">
                <label>BIC/SWIFT</label>
              </div>
              <div className="data-prop">
                {!editMode && <div className="value">{this.state.model.bic || '[missing]'} </div>}
                {editMode &&
                  <InputField type='text' maxlength={11}
                    ref={this.inputs.bic}
                    value={this.state.model.bic}
                    placeholder='BIC/SWIFT'
                    validators={[
                      { check: Validators.required, message: 'This field is required' },
                      { check: Validators.minlength8, message: 'Valid BIC number is 8 to 11 characters in length' }
                    ]}
                    onChange={this.handleChange('bic')} />
                }
              </div>
            </div>




          </div>

          <div className="actions-row">

            {!editMode &&
              <a className="a-button go" href="#edit" onClick={() => this.setEditMode(true)}>
                Edit data
                </a>
            }

            {editMode &&
              <a className="a-button secondary cancel" href="#cancel"
                onClick={() => {
                  this.resetModel();
                  this.setEditMode(false);
                }
                }>
                Cancel edit
                </a>
            }

            {editMode &&
              <a className={'a-button save' + (hasError ? ' errors ' : ' ')} href="#save" onClick={() => {
                this.triggerSave();
              }}>
                Save changes
                </a>
            }

          </div>


        </div>
      </div>

    )
  }
}










BankAccountNo.propTypes = {};

BankAccountNo.defaultProps = {};

export default BankAccountNo;
