import { UserTypes, OnboardingState,  } from './userTypes';

/**
 * Shouldnt this
 * @param {*} user 
 * @param {*} checkForOnboardedStateOnly 
 */
const isUserAllowedToEdit = (user, checkForOnboardedStateOnly = false) => {
    if (user.UserType && (!checkForOnboardedStateOnly || user.OnboardingState === OnboardingState.Onboarded)) {
      if ([UserTypes.Modum, UserTypes.TokenSwapped].includes(user.UserType)) {
        return true;
      }
      if ([UserTypes.CashSwapped, UserTypes.CashNoSwap, UserTypes.TokenNoSwap, UserTypes.NotOnboarded].includes(user.UserType)) {
        console.log("User is not allowed to edit information", user.id, user.OnboardingState, user.UserType);
        return false;
      }
    }
  
    return true;
  };


  export default  isUserAllowedToEdit;