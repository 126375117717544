import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import Amplify from 'aws-amplify';
//import config from './aws-exports'
import config from './helpers/awsConfigs'
Amplify.configure(config);

//import Auth from '@aws-amplify/auth';
//API.configure(awsconfig);
//Auth.configure(awsconfig);

//console.log(process);
console.log(process.env);
console.log(config);
//console.log(configV2);

ReactDOM.render(
  <React.StrictMode>
   
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
