const OnboardingState = Object.freeze({
    Initial: "Initial",
    AppTermsAndConditions: "AppTermsAndConditions",
    SignUp: "SignUp",
    VerifyEmail: "VerifyEmail",
    PersonalInformation: "PersonalInformation",
    CorrespondenceAddress: "CorrespondenceAddress",
    BeneficialOwner: "BeneficialOwner",
    IdentityVerification: "IdentityVerification",
    TokenSwapTerms: "TokenSwapTerms",
    BankAccount: "BankAccount",
    BurnAddress: "BurnAddress",
    Onboarded: "Onboarded"
  });
  
  const KycState = Object.freeze({
    Initial: "Initial",
    Submitted: "Submitted",
    Accepted: "Accepted",
    Declined: "Declined",
    Error: "Error"
  });
  
  const UserTypes = Object.freeze({
    Modum: "Modum",
    CashSwapped: "CashSwapped",
    TokenSwapped: "TokenSwapped",
    CashNoSwap: "CashNoSwap",
    TokenNoSwap: "TokenNoSwap",
    NotOnboarded: "NotOnboarded"
  });
  
  module.exports = {
    OnboardingState,
    KycState,
    UserTypes
  };
  