import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {validateInput} from "./../../utilities/Validator";
import { input } from 'aws-amplify';

//}

class InputField extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
        error: null
    }
    this.inputControl = React.createRef();
  }

  focus = () => {
      setImmediate(() => { this.inputControl.current.focus() });
  }

  setError = (err) => {
      if(err) {
        this.setState( {error: err });
      } else {
        this.setState( {error: null });
      }
  };

    handleChange = (event) => {
        const {value} = event.target;
        this.setError(validateInput(this.props.validators, value));
        this.props.onChange(value);
    };
    
    validate = (value) => {
        let errors = validateInput(this.props.validators, this.props.value);
        this.setError(errors);
        return errors;
    }

render() {
    
    return (
        <div className="form-group">
            {this.props.label && <label htmlFor="app-input-field">{this.props.label}</label>}

            {this.props.type === 'textarea' ? (
                <textarea
                    ref={this.inputControl}
                    className='form-control'
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    onChange={this.handleChange}
                    spellCheck="false" 
                />
            ) : (
                <input
                    ref={this.inputControl}
                    spellCheck="false" 
                    type={this.props.type}
                    value={this.props.value}
                    className='form-control'
                    placeholder={this.props.placeholder}
                    maxLength={this.props.maxlength}
                    onChange={this.handleChange}
                />
                
            )}
            <div className="activity"></div>
            {this.state.error && <div className='text-danger'>{this.state.error.message}</div>}
        </div>
    )
}
};

InputField.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    validators: PropTypes.array,
    type: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

InputField.defaultProps = {
  value: '',
  label: '',
  placeholder: '',
  type: 'text',
  validators: []
};

export default InputField;
