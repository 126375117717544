
import React, { useDebugValue, useEffect, useState, Component } from 'react';
import styles from './UserMainAddress.module.scss';
import InputField from './../InputField'
import { Validators } from './../../utilities/Validator'

class UserMainAddress extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      editMode: false,
      model: {
        firstName: "",
        lastName: "",
        street: "",
        streetNumber: "",
        city: "",
        postCode: "",
        country: ""
      }
    }

    this.inputs = {
      firstName: React.createRef(),
      lastName: React.createRef(),
      street: React.createRef(),
      streetNumber: React.createRef(),
      postCode: React.createRef(),
      city: React.createRef(),
      country: React.createRef()
    }
  }
  setEditMode = (yes) => { 
    
    this.setState({ editMode: yes });
    
    if(yes) {
      setImmediate(() => {
        this.inputs.firstName.current.focus();
      });
    }
  }
  
  resetModel = () => {
    Object.keys(this.untouchedCopy).forEach(key => { 
      this.state.model[key] = this.untouchedCopy[key]; 
    })
    this.validate();
  }

  setModel  = (model) => {
    this.untouchedCopy = JSON.parse(JSON.stringify(model));
    
    Object.keys(model).forEach(key => { 
      this.setState({ model: { ...this.state.model, [key]: model[key]} }) 
    });
  }

  triggerSave = () => {
    
    if(!this.validate()) {
      this.props.onError("Form contains errors, please fill all mandatory fields.");
    } else {
      this.props.onSave(this.state.model);
    }
  }

  handleChange = (key) => (value) => {
    
    this.setState({ model: { ...this.state.model, [key]: value }});
    setImmediate(() => { this.validate() } );

  };

  validate = () => {
    
    var hasError = false;
    
    Object.keys(this.inputs).forEach(name => {
      if(this.inputs[name].current.validate() !== false) {
        hasError = true;
      }
    });

    this.setState({ hasError: hasError });  
    
    return !hasError;

  }

  render() {
    const hasError = this.state.hasError;
    const editMode = this.state.editMode;

    return (

      <div className={styles.UserMainAddress} data-testid="UserMainAddress">

        <div className="profile-block">
          {this.props.header &&
            <div className="header">
              {this.props.header}
            </div>
          }
          {this.props.description &&
            <div className="description">
              {this.props.description}
            </div>
          }
            

          <div className="content">

            <div className="data-row">
              <div className="data-label">
                <label>First Name</label>
              </div>
              <div className="data-prop">
                {!editMode && <div className="value">{this.state.model.firstName} </div>}
                {editMode &&
                  <InputField type='text' maxlength={40}
                    ref={this.inputs.firstName}
                    value={this.state.model.firstName}
                    placeholder='First Name'
                    validators={[{ check: Validators.required, message: 'This field is required' }]}
                    onChange={this.handleChange('firstName')} />
                }
              </div>
            </div>


            <div className="data-row">
              <div className="data-label">
                <label>Last Name</label>
              </div>
              <div className="data-prop">
                {!editMode && <div className="value">{this.state.model.lastName} </div>}
                {editMode &&
                  <InputField type='text' maxlength={40}
                    ref={this.inputs.lastName}
                    value={this.state.model.lastName}
                    placeholder='Last Name'
                    validators={[{ check: Validators.required, message: 'This field is required' }]}
                    onChange={this.handleChange('lastName')} />
                }
              </div>
            </div>

            <div className="data-row">
              <div className="data-label">
                <label>Street</label>
              </div>
              <div className="data-prop">
                {!editMode && <div className="value">{this.state.model.street} </div>}
                {editMode &&
                  <InputField type='text' maxlength={100}
                    ref={this.inputs.street}
                    value={this.state.model.street}
                    placeholder='Street'
                    validators={[{ check: Validators.required, message: 'This field is required' }]}
                    onChange={this.handleChange('street')} />
                }
              </div>
            </div>

            <div className="data-row">
              <div className="data-label">
                <label>Street Number</label>
              </div>
              <div className="data-prop">
                {!editMode && <div className="value">{this.state.model.streetNumber} </div>}
                {editMode &&
                  <InputField type='text' maxlength={10}
                    ref={this.inputs.streetNumber}
                    value={this.state.model.streetNumber}
                    placeholder='Street Number'
                    validators={[{ check: Validators.required, message: 'This field is required' }]}
                    onChange={this.handleChange('streetNumber')} />
                }
              </div>
            </div>            
            

            <div className="data-row">
              <div className="data-label">
                <label>Post Code</label>
              </div>
              <div className="data-prop">
                {!editMode && <div className="value">{this.state.model.postCode} </div>}
                {editMode &&
                  <InputField type='text' maxlength={10}
                    ref={this.inputs.postCode}
                    value={this.state.model.postCode}
                    placeholder='Post Code'
                    validators={[{ check: Validators.required, message: 'This field is required' }]}
                    onChange={this.handleChange('postCode')} />
                }
              </div>
            </div>            

            <div className="data-row">
              <div className="data-label">
                <label>City</label>
              </div>
              <div className="data-prop">
                {!editMode && <div className="value">{this.state.model.city} </div>}
                {editMode &&
                  <InputField type='text' maxlength={40}
                    ref={this.inputs.city}
                    value={this.state.model.city}
                    placeholder='City'
                    validators={[{ check: Validators.required, message: 'This field is required' }]}
                    onChange={this.handleChange('city')} />
                }
              </div>
            </div>            


            <div className="data-row">
              <div className="data-label">
                <label>Country</label>
              </div>

              <div className="data-prop">
                {!editMode && <div className="value">{this.state.model.country} </div>}
                {editMode &&
                  <InputField type='text' maxlength={40}
                    ref={this.inputs.country}
                    value={this.state.model.country}
                    placeholder='Country'
                    validators={[{ check: Validators.required, message: 'This field is required' }]}
                    onChange={this.handleChange('country')} />
                }
              </div>
            </div>


          </div>

          <div className="actions-row">

              { !editMode && 
                <a className="a-button go" href="#edit" onClick={ () => this.setEditMode(true) }> 
                  Edit data
                </a> 
              }

              { editMode &&
                <a  className="a-button secondary cancel" href="#cancel" 
                    onClick={ () => { 
                      this.resetModel(); 
                      this.setEditMode(false); } 
                    }>
                  Cancel edit
                </a>
              }
              
              { editMode &&
                <a className={ 'a-button save' + (hasError ? ' errors ' : ' ') } href="#save" onClick={ () => { 
                    this.triggerSave();
                  } }>
                  Save changes
                </a>
              }

          </div>


        </div>
      </div>

    )
  }
}

UserMainAddress.propTypes = {};

UserMainAddress.defaultProps = {};

export default UserMainAddress;
