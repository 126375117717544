import React, { Fragment, useState } from "react";

export const ConfidentialityAgreement = (props) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <Fragment>
      <div className="profile-block">
        <div className="header">Confidentiality Confirmation</div>

        <div className="description">
          The information contained on the following documents is confidential,
          privileged and only for the information of participation certificate
          holders and may not be used, published or redistributed without the
          prior written consent of modum.io AG.
          <br />
          <br />
          According to the Swiss Code of Obligations (Art Art. 656c:
          <span> </span>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.fedlex.admin.ch/eli/cc/27/317_321_377/de#art_656_c"
          >
            link
          </a>
          ) modum.io AG is not obliged to share the provided information. We
          decided to disclose this information on a voluntary basis. We reserve
          the right to remove access to the documents at any time with no
          reasoning or prior warning.
          <br />
          <br /> The financial statements contained in this Report have been
          prepared in accordance with Swiss Code of Obligations and have been
          audited by PwC. The information provided is in good faith and while
          every care has been taken in preparing these documents, modum.io AG
          makes no representations and gives no warranties of whatever nature in
          respect of these documents, including but not limited to the accuracy
          or completeness of any information, facts and/or opinions contained
          therein. Modum.io AG, its subsidiaries, the directors and employees
          cannot be held liable for the use of and reliance of the findings in
          these documents.
        </div>

        <div className="description">
          <label>
            <input
              className="double"
              onClick={() => {
                setIsChecked(!isChecked);
              }}
              type="checkbox"
              value={isChecked}
            />
            <b className="bold">I agree</b>
          </label>
        </div>

        <button
          className={"a-button go" + (isChecked ? "" : " disabled")}
          href="#edit"
          onClick={() => {
            if (!isChecked) {
              return;
            }
            localStorage.setItem("confidentiality_agreement_confirmed", "yes");
            props.setIsAgreed(true);
          }}
        >
          confirm
        </button>
      </div>
    </Fragment>
  );
};
