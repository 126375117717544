/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react'
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib'
import { Storage } from 'aws-amplify'

export const CorporateDocuments = (props) => {
  const [folders, setFolders] = useState([])

  async function getDocument(folder, file) {
    Storage.get((folder + file).replace('root/', ''), { download: true }).then(
      async (data) => {
        const existingPdfBytes = await data.Body.arrayBuffer()
        const pdfDoc = await PDFDocument.load(existingPdfBytes)
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
        const pages = pdfDoc.getPages()

        pages.map((page) => {
          const { height } = page.getSize()
          page.drawText(
            'User ID: ' + global.exposed_auth.user.attributes.email,
            {
              x: 40,
              y: height / 2 + 300,
              size: 35,
              font: helveticaFont,
              opacity: 0.1,
              color: rgb(0.95, 0.1, 0.1),
              rotate: degrees(-45),
            }
          )
        })

        const downloadURL = (data, fileName) => {
          const a = document.createElement('a')
          a.href = data
          a.download = fileName
          document.body.appendChild(a)
          a.style = 'display: none'
          a.click()
          a.remove()
        }

        const pdfBytes = await pdfDoc.save()
        const b64 =
          'data:application/pdf;base64,' +
          Buffer.from(pdfBytes).toString('base64')

        downloadURL(b64, `watermarked_${file}.pdf`)
      }
    )
  }

  useEffect(() => {
    Storage.list('').then((data) => {
      console.log(data)
      const parsedData = {}
      parsedData['root/'] = { files: [] }

      const folders = []

      data
        .map((item) => item.key)
        .filter((item) => item.length)
        .forEach((name) => {
          console.log(name)
          if (name.charAt(name.length - 1) === '/') {
            console.log(name)
            folders.push(name)
          } else {
            const found = folders.find((folder) => name.includes(folder))
            if (found && !parsedData[found]) {
              parsedData[found] = { files: [] }
            }
            if (found) {
              parsedData[found].files.push(name.replace(found, ''))
            } else {
              parsedData['root/'].files.push(name)
            }
          }
        })

      const finalData = []

      ;[...folders, 'root/'].forEach((folder) => {
        finalData.push({ name: folder, files: parsedData[folder].files })
      })

      setFolders(finalData.filter((element) => element.files.length))
    })
  }, [])

  return (
    <Fragment>
      <div className="profile-block">
        <div className="header">Corporate Documents</div>
        {folders.map((folder) => {
          return (
            <div key={folder.name + '-xyz'} className="description bold">
              {folder.name.slice(0, folder.name.length - 1)}{' '}
              <ul>
                {folder.files.map((file) => (
                  <li key={file + 'xyz'}>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault()
                        getDocument(folder.name, file)
                      }}
                    >
                      {file}
                    </a>{' '}
                  </li>
                ))}
              </ul>
            </div>
          )
        })}
      </div>
    </Fragment>
  )
}
