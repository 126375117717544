import React from 'react';
import PropTypes from 'prop-types';
import styles from './UserProfile.module.scss';
import InputField from './../InputField'
import { Validators } from './../../utilities/Validator'

/**
 * User Profile form
 */
class UserProfile extends React.Component {

  state = {
    id: '',
    email: '',
  }

  /**
   * Callback to parent comonent on user action
   */
  triggerSave = () => {
    //TODO: per prop
    this.props.model.correspondenceAddress = this.state.correspondenceAddress;
    this.props.saveProfile(this.props.model);
  }

  
  /**
   * Called by parent when model refreshes, without this call 
   * InputFields values are not updating.
   */
  updateInputs = () => {
    this.setState(this.props.model)
  }

  render() {
    const { text, country, message, acceptance } = this.state;
    return (
      <div className={styles.UserProfile} data-testid="UserProfile">

        <div className="profile-block-wrap">
          <div className="profile-block">
            <div className="header">
              Your Account
            </div>
            <div className="content info-only">
              <div className="data-row">
                <div className="data-label">
                  <label>Account email</label>
                </div>
                <div className="data-prop">
                  {this.props.model.email}
                </div>
              </div>
            </div>
          </div>

        </div>



      </div>
    )
  }
}

UserProfile.propTypes = {};

UserProfile.defaultProps = {};

export default UserProfile;
